import {
  mainActionTypes,
  SELECT_ADDRESS,
  SELECT_EKSPEDISI,
  SELECT_CABANG,
  SELECT_GROUP_KAIN,
  SELECT_JENIS_KAIN,
  SELECT_WARNA_KAIN,
  SELECT_JENIS_PAKAIAN,
  SET_TOKEN,
  SET_ID_TRANSAKSI,
  SET_NO_TRANSAKSI,
  SET_IN_PROTECTED_ROUTE,
  SET_NO_TRANSAKSI_NO_HP,
} from "./types";

export const setToken = (token: string): mainActionTypes => ({
  type: SET_TOKEN,
  payload: { token },
});

export const setIdTransaksi = (idTransaksi: string | null): mainActionTypes => ({
  type: SET_ID_TRANSAKSI,
  payload: { idTransaksi },
});

export const setNoTransaksi = (noTransaksi: string): mainActionTypes => ({
  type: SET_NO_TRANSAKSI,
  payload: { noTransaksi },
});

export const setNoTransaksiNoHp = (
  noTransaksi: string,
  noHp: string,
  nama: string,
): mainActionTypes => ({
  type: SET_NO_TRANSAKSI_NO_HP,
  payload: { noTransaksi, noHp, nama },
});

export const selectAddress = (address: Address | null): mainActionTypes => ({
  type: SELECT_ADDRESS,
  payload: { address },
});

export const selectEkspedisi = (ekspedisi: string): mainActionTypes => ({
  type: SELECT_EKSPEDISI,
  payload: { ekspedisi },
});

export const selectCabang = (cabang: Cabang): mainActionTypes => ({
  type: SELECT_CABANG,
  payload: { cabang },
});

export const selectGroupKain = (groupKainId: number): mainActionTypes => ({
  type: SELECT_GROUP_KAIN,
  payload: { groupKainId },
});

export const selectJenisKain = (jenisKainName: string): mainActionTypes => ({
  type: SELECT_JENIS_KAIN,
  payload: { jenisKainName },
});

export const selectWarnaKain = (warnaKain: WarnaKain | null): mainActionTypes => ({
  type: SELECT_WARNA_KAIN,
  payload: { warnaKain },
});

export const selectJenisPakaian = (jenisPakaian: string): mainActionTypes => ({
  type: SELECT_JENIS_PAKAIAN,
  payload: { jenisPakaian },
});

export const setInProtectedRoute = (inProtectedRoute: boolean): mainActionTypes => ({
  type: SET_IN_PROTECTED_ROUTE,
  payload: { inProtectedRoute },
});
