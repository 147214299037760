import React, { useState } from "react";
import loadable from "@loadable/component";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { Offline } from "react-detect-offline";
import LoadingPage from "./pages/Loading";
import LinkExpired from "./pages/LinkExpired";
import ProtectedRoute from "./components/ProtectedRoute";
import TourGuide from "./components/TourGuide";
import Snackbar from "./components/Snackbar";
import useAxiosInterceptors from "./helpers/axiosInterceptor";
import useGTM from "./helpers/useGTM";
import useHotjar from "./helpers/useHotjar";
// import useGAEnhancedEcommerce from "./helpers/useGAEnhancedEcommerce";
import Pixel from "./helpers/usePixel";
import OnChangeRoute from "./helpers/OnChangeRoute";
import "@/helpers/preact-google-translate-patch";
import "intersection-observer";
import { NotifSlowConnection } from "./components/NotifSlowConnection";
import ExpiredOrder from "./pages/ExpiredOrder";
import ExpiredOrderCancel from "./pages/ExpiredOrderCancel";
import { HOST } from "@/strings";

const lcOpts = { fallback: <LoadingPage /> };

const AddressPage = loadable(() => import("./pages/Address"), lcOpts);
const BookingPage = loadable(() => import("./pages/Booking"), lcOpts);
const CartPage = loadable(() => import("./pages/Cart"), lcOpts);
const CatalogPage = loadable(() => import("./pages/Catalog"), lcOpts);
const CheckoutPage = loadable(() => import("./pages/Checkout"), lcOpts);
const HomePage = loadable(() => import("./pages/Home"), lcOpts);
const MainPage = loadable(() => import("./pages/Main"), lcOpts);
const ListOrderPage = loadable(() => import("./pages/ListOrder"), lcOpts);
const KalkulatorPage = loadable(() => import("./pages/KalkulatorBahan/KalkulatorBahan"), lcOpts);
const CalculationResult = loadable(
  () => import("./pages/KalkulatorBahan/CalculationResult"),
  lcOpts,
);

const Simulasi = loadable(() => import("./pages/KalkulatorBahan/Simulasi/Simulasi"), lcOpts);
const DetailSimulasi = loadable(
  () => import("./pages/KalkulatorBahan/Simulasi/DetailSimulasi"),
  lcOpts,
);
const EditSimulasi = loadable(
  () => import("./pages/KalkulatorBahan/Simulasi/EditSimulasi"),
  lcOpts,
);
const FormBuktiPembayaran = loadable(
  () => import("./pages/KonfirmasiPayment/FormBuktiPembayaran"),
  lcOpts,
);
const KritikSaran = loadable(() => import("./pages/KritikSaran"), lcOpts);
const KonfirmasiRincian = loadable(() => import("./pages/KonfirmasiRincian"), lcOpts);
const RincianOrder = loadable(() => import("./pages/RincianOrder"), lcOpts);
const OrderPlaced = loadable(() => import("./pages/OrderPlaced"), lcOpts);
const DetailKain = loadable(() => import("./pages/Main/DetailKain"), lcOpts);
const DetailCatalog = loadable(() => import("./pages/Catalog/CatalogDetail"), lcOpts);
const StatusOrder = loadable(() => import("./pages/StatusOrder"), lcOpts);
const MintaSample = loadable(() => import("./pages/Main/MintaSample"), lcOpts);
const JenisKainPage = loadable(() => import("./pages/Main/JenisKainPage"), lcOpts);
const PaymentPlaced = loadable(() => import("./pages/SuccessPayment"), lcOpts);
const ListOrderKonfirmasi = loadable(() => import("./pages/KonfirmasiPayment"), lcOpts);
const RegisterVisitorId = loadable(() => import("./pages/RegisterVisitorId"), lcOpts);
const EditOrderSuccess = loadable(() => import("./pages/EditOrderStatus/SuccessEditOrder"), lcOpts);
const OrderAlreadyPaid = loadable(() => import("./pages/EditOrderStatus/OrderAlreadyPaid"), lcOpts);
const OrderAlreadyEdited = loadable(
  () => import("./pages/EditOrderStatus/OrderAlreadyEdited"),
  lcOpts,
);
const EditOrderFailed = loadable(() => import("./pages/EditOrderStatus/EditOrderFailed"), lcOpts);
const OrderInProgress = loadable(() => import("./pages/EditOrderStatus/OrderInProgress"), lcOpts);
const LinkEditOrderExpired = loadable(
  () => import("./pages/EditOrderStatus/LinkEditOrderExpired"),
  lcOpts,
);
const EditOrderCanceled = loadable(
  () => import("./pages/EditOrderStatus/EditOrderCanceled"),
  lcOpts,
);
const EditCanceled = loadable(() => import("./pages/EditOrderStatus/EditCanceled"), lcOpts);
const FailedGetDataEditOrder = loadable(
  () => import("./pages/EditOrderStatus/FailedGetDataEditOrder"),
  lcOpts,
);
const FormPKP = loadable(() => import("./pages/FormPKP"), lcOpts);

const App: React.FC = () => {
  useAxiosInterceptors();
  useHotjar(2000);
  // useGAEnhancedEcommerce();
  useGTM();

  const [showNotif, setShowNotif] = useState(false);

  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <OnChangeRoute />
        <Pixel />
        <Switch>
          <Route path="/link/:notransaksi" component={HomePage} />
          <Route path="/A/:notransaksi" component={HomePage} />
          <ProtectedRoute path="/B/:notransaksi" component={HomePage} />
          <ProtectedRoute path="/C/:notransaksi" component={HomePage} />
          <ProtectedRoute path="/D/:notransaksi" component={HomePage} />
          <ProtectedRoute path="/F/:notransaksi" component={FormBuktiPembayaran} />
          <ProtectedRoute path="/G/:notransaksi" component={KritikSaran} />
          <ProtectedRoute path="/stock/:notransaksi" component={MainPage} />
          <ProtectedRoute path="/order/:notransaksi" component={MainPage} />
          <ProtectedRoute path="/sample/:notransaksi" component={MainPage} />
          <ProtectedRoute path="/catalog/:notransaksi" component={CatalogPage} />
          <ProtectedRoute path="/address/:notransaksi" component={AddressPage} />
          <ProtectedRoute path="/booking/:notransaksi" component={BookingPage} />
          <ProtectedRoute path="/cart/:notransaksi" component={CartPage} />
          <ProtectedRoute path="/edit-order/:notransaksi" component={CartPage} />
          <ProtectedRoute path="/checkout/:notransaksi" component={CheckoutPage} />
          <ProtectedRoute path="/list-order/:notransaksi" component={ListOrderPage} />
          <ProtectedRoute
            path="/konfirmasi-pembayaran/:notransaksi"
            component={ListOrderKonfirmasi}
          />
          <ProtectedRoute path="/order-details/:notransaksi" component={RincianOrder} />
          <ProtectedRoute path="/bukti-pembayaran/:notransaksi" component={FormBuktiPembayaran} />
          <ProtectedRoute path="/detail-kain/:notransaksi" component={DetailKain} />
          <ProtectedRoute path="/rinciankonfirmasi/:notransaksi" component={KonfirmasiRincian} />
          <ProtectedRoute path="/jenis-kain/:notransaksi" component={JenisKainPage} />
          <ProtectedRoute path="/kalkulator-bahan/:notransaksi" component={KalkulatorPage} />
          <ProtectedRoute path="/hasil-perhitungan/:notransaksi" component={CalculationResult} />
          <ProtectedRoute path="/list-simulasi/:notransaksi" component={Simulasi} />
          <ProtectedRoute
            path="/detail-simulasi/:simulationID/:notransaksi"
            component={DetailSimulasi}
          />
          <ProtectedRoute path="/rincian-simulasi/:notransaksi" component={CalculationResult} />
          <ProtectedRoute
            path="/edit-simulasi/:simulationID/:notransaksi"
            component={EditSimulasi}
          />

          <ProtectedRoute path="/catalog-detail/:notransaksi" component={DetailCatalog} />
          <ProtectedRoute path="/sample-detail/:notransaksi" component={MintaSample} />
          <Route path="/formpkp" component={FormPKP} />
          <Route path="/link-invalid" component={LinkExpired} />
          <Route path="/order-placed" component={OrderPlaced} />
          <Route path="/payment-success" component={PaymentPlaced} />
          <Route path="/expired/:notransaksi" component={ExpiredOrder} />
          <Route path="/expired-cancel/:notransaksi" component={ExpiredOrderCancel}></Route>
          <Route path="/set-visitor-id" component={RegisterVisitorId} />
          <Route path="/edit-order-success/:notransaksi" component={EditOrderSuccess} />
          <Route path="/order-paid/:notransaksi" component={OrderAlreadyPaid} />
          <Route path="/order-already-edited/:notransaksi" component={OrderAlreadyEdited} />
          <Route path="/edit-order-failed/:notransaksi" component={EditOrderFailed} />
          <Route path="/order-in-progress/:notransaksi" component={OrderInProgress} />
          <Route path="/link-order-expired/:notransaksi" component={LinkEditOrderExpired} />
          <Route path="/edit-order-canceled/:notransaksi" component={EditOrderCanceled} />
          <Route path="/edit-canceled/:notransaksi" component={EditCanceled} />
          <Route path="/edit-error/:notransaksi" component={FailedGetDataEditOrder} />
          <ProtectedRoute path="/statusorder/:notransaksi" component={StatusOrder} />
          <Route path="/" component={HomePage} />
        </Switch>
        <TourGuide />
        <Snackbar />
        <Offline
          polling={{
            enabled: true,
            url: HOST,
            interval: 15000,
            timeout: 5000,
          }}
          onChange={(online) => setShowNotif(!online)}
        />
        {showNotif ? <NotifSlowConnection onCLose={() => setShowNotif(false)} /> : null}
      </QueryParamProvider>
    </Router>
  );
};

export default App;
