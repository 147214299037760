import { PURGE } from "redux-persist";

export interface paymentState {
  rekening?: Rekening;
}

export const SET_REKENING = "@payment/SET_REKENING";

interface setPaymentMethod {
  type: typeof SET_REKENING;
  payload: { rekening: Rekening };
}

interface purgeAction {
  type: typeof PURGE;
}

export type paymentActionTypes = setPaymentMethod | purgeAction;
