import { PURGE } from "redux-persist";

export type BodyUnits = "kg" | "roll" | "roll + kg";

export interface orderState {
  bodyUnits: BodyUnits;
  bodyQty: string;
  aksesoris: AksesorisQtyList;
}

export const CHANGE_BODY_UNITS = "@order/CHANGE_BODY_UNITS";
export const SET_BODY_QTY = "@order/SET_BODY_QTY";
export const SET_AKSESORIS_QTY = "@order/SET_AKSESORIS_QTY";
export const SET_AKSESORIS_LIST = "@order/SET_AKSESORIS_LIST";

export const CHANGE_AKSESORIS_QTY_STD = "@order/CHANGE_AKSESORIS_QTY_STD";

export const CLEAR_ORDER = "@order/CLEAR_ORDER";

interface changeBodyUnits {
  type: typeof CHANGE_BODY_UNITS;
  payload: { bodyUnits: BodyUnits };
}

interface setbodyQty {
  type: typeof SET_BODY_QTY;
  payload: { bodyQty: string };
}

interface setAksesorisQty {
  type: typeof SET_AKSESORIS_QTY;
  payload: { index: number; qty: string };
}

interface setAksesorisList {
  type: typeof SET_AKSESORIS_LIST;
  payload: { aksesoris: AksesorisQtyList };
}

interface changeAksesorisQtyStd {
  type: typeof CHANGE_AKSESORIS_QTY_STD;
  payload: { index: number; state: boolean };
}

interface clearOrder {
  type: typeof CLEAR_ORDER;
  payload: {};
}

interface purgeAction {
  type: typeof PURGE;
}

export type orderActionTypes =
  | changeBodyUnits
  | setbodyQty
  | setAksesorisList
  | setAksesorisQty
  | changeAksesorisQtyStd
  | clearOrder
  | purgeAction;
