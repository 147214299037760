import { CreateSlice } from "./useStore";

export type RegionSlice = {
  selectedProvince: string;
  selectedProvincePIC: string;
  selectedProvinceShipping: string;
  selectedCity: string;
  selectedCityPIC: string;
  selectedCityShipping: string;
  selectedDistrict: string;
  selectedDistrictPIC: string;
  selectedDistrictShipping: string;
  selectedVillage: string;
  selectedVillagePIC: string;
  selectedVillageShipping: string;
  setSelectedProvince: (selectedProvince: string) => any;
  setSelectedProvincePIC: (selectedProvincePIC: string) => any;
  setSelectedProvinceShipping: (selectedProvinceShipping: string) => any;
  setSelectedCityPIC: (selectedCityPIC: string) => any;
  setSelectedCity: (selectedCity: string) => any;
  setSelectedCityShipping: (selectedCityShipping: string) => any;
  setSelectedDistrict: (selectedDistrict: string) => any;
  setSelectedDistrictPIC: (selectedDistrictPIC: string) => any;
  setSelectedDistrictShipping: (selectedDistrictShipping: string) => any;
  setSelectedVillage: (selectedVillage: string) => any;
  setSelectedVillagePIC: (selectedVillagePIC: string) => any;
  setSelectedVillageShipping: (selectedVillageShipping: string) => any;
};
export const initialState = {
  selectedProvince: "",
  selectedProvincePIC: "",
  selectedProvinceShipping: "",
  selectedCity: "",
  selectedCityPIC: "",
  selectedCityShipping: "",
  selectedDistrict: "",
  selectedDistrictPIC: "",
  selectedDistrictShipping: "",
  selectedVillage: "",
  selectedVillagePIC: "",
  selectedVillageShipping: "",
};
const createRegionSlice: CreateSlice<RegionSlice> = (set): RegionSlice => ({
  ...initialState,
  setSelectedCityPIC: (selectedCityPIC) => set({ selectedCityPIC }),
  setSelectedCity: (selectedCity) => set({ selectedCity }),
  setSelectedCityShipping: (selectedCityShipping) => set({ selectedCityShipping }),
  setSelectedProvince: (selectedProvince) => set({ selectedProvince }),
  setSelectedProvincePIC: (selectedProvincePIC) => set({ selectedProvincePIC }),
  setSelectedProvinceShipping: (selectedProvinceShipping) => set({ selectedProvinceShipping }),
  setSelectedDistrict: (selectedDistrict) => set({ selectedDistrict }),
  setSelectedDistrictPIC: (selectedDistrictPIC) => set({ selectedDistrictPIC }),
  setSelectedDistrictShipping: (selectedDistrictShipping) => set({ selectedDistrictShipping }),
  setSelectedVillage: (selectedVillage) => set({ selectedVillage }),
  setSelectedVillagePIC: (selectedVillagePIC) => set({ selectedVillagePIC }),
  setSelectedVillageShipping: (selectedVillageShipping) => set({ selectedVillageShipping }),
});
export default createRegionSlice;
