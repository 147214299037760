import useSWR from "swr/immutable";
import axios from "axios";
import { HOST } from "@/strings";
import useToken from "@/helpers/useToken";
import fixCabang from "@/helpers/fixCabang";
import { useMemo } from "react";

const URL = HOST + "/cabang/";

const fetcher = async (_: string, authorization: any) =>
  authorization && axios.get(URL, { headers: { authorization } }).then((res) => res.data);

export const useCabang = () => {
  const token = useToken();
  const { data, mutate, error } = useSWR(token ? [URL, token] : null, fetcher);

  const loading = !data && !error;

  const payload: Cabang[] = useMemo(() => {
    if (!Array.isArray(data?.values?.data)) return [];
    return fixCabang(data.values.data);
  }, [data?.values?.data]);

  return {
    loading,
    error,
    data: payload,
    mutate,
  };
};

export default useCabang;
