import React, { useCallback, useMemo } from "react";
import { Helmet } from "react-helmet";
import WhatsappSvg from "@/assets/SVGs/whatsapp-white.svg";
import { KNITTO_WHATSAPP_NUMBER } from "@/strings";
import { Page } from "@/components/Layout";
import { useQueryParam, StringParam } from "use-query-params";
import styles from "./LinkExpired.module.scss";

const LinkExpired: React.FC = () => {
  const [page] = useQueryParam("p", StringParam);

  const message = useMemo(() => {
    switch (page) {
      case "order":
        return "Saya ingin mendapatkan link baru untuk mengakses fitur order kain.";

      case "catalog":
        return "Saya ingin mendapatkan link baru untuk mengakses fitur katalog warna.";

      case "sample":
        return "Saya ingin mendapatkan link baru untuk mengakses fitur sample kain.";

      case "status-order":
        return "Saya ingin mendapatkan link untuk mengakses fitur status order";

      case "kalkulator-bahan":
        return "Saya ingin mendapatkan link untuk mengakses fitur kalkulator bahan";

      default:
        return "Saya ingin mendapatkan link baru ke website Portal";
    }
  }, [page]);

  const title = useMemo(() => {
    switch (page) {
      case "order":
        return "Klik button di bawah untuk menikmati fitur order kain melalui Customer Portal";

      case "catalog":
        return "Klik button di bawah untuk menikmati fitur order katalog melalui Customer Portal";

      case "sample":
        return "Klik button di bawah untuk menikmati fitur minta sample melalui Customer Portal";

      case "status-order":
        return "Klik button di bawah untuk melihat status order-mu melalui Customer Portal";

      case "kalkulator-bahan":
        return "Klik button di bawah untuk menikmati fitur kalkulator bahan di Customer Portal";

      default:
        return "Maaf, untuk bisa menikmati fitur ini kembali, silahkan klik tombol di bawah";
    }
  }, [page]);

  const pageName = useMemo(() => {
    switch (page) {
      case "order":
        return "Minta Akses ke Order Kain";

      case "catalog":
        return "Minta Akses ke Katalog Warna";

      case "sample":
        return "Minta Akses ke Sample Kain";

      case "status-order":
        return "Minta Akses ke Status Order";

      case "kalkulator-bahan":
        return "Minta Akses ke Kalkulator Bahan";

      default:
        return "Minta Akses Portal";
    }
  }, [page]);

  const waButtonClickHandler = useCallback(() => {
    window.open(`https://wa.me/${KNITTO_WHATSAPP_NUMBER}?text=${message}`, "_self");
  }, [message]);

  return (
    <Page
      style={{
        backgroundImage: "url(https://s3.knitto.co.id/svg/blue-background.svg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        display: "flex",
      }}
      className={styles.page}
    >
      <Helmet>
        <title>{pageName + " | Toko Bahan Kaos Knitto"}</title>
      </Helmet>
      <div className={styles.image}>
        <img
          src="https://s3.knitto.co.id/svg/order-via-mobile-phone.svg"
          alt="order-via-mobile-phone"
          height={265}
        />
      </div>
      <p className="dtm-s2 kp-0 km-0 kmb-2-4 navy-100 ktext-center kml-1 kmr-1">{title}</p>
      <span className={styles.dividerText}>...</span>
      <button
        className={styles.whastAppBtn}
        onClick={waButtonClickHandler}
        data-testid="btnMintaAksesWA"
      >
        <div className={styles.whatsAppIcon}>
          <img src={WhatsappSvg} alt="wa-circle" />
        </div>
        <div>{pageName}</div>
      </button>
    </Page>
  );
};

export default LinkExpired;
