import { PURGE } from "redux-persist";

export const OPSI_DIKIRIM = "dikirim";
export const OPSI_DIAMBIL = "diambil";

export type OpsiPengambilan = typeof OPSI_DIKIRIM | typeof OPSI_DIAMBIL;

export interface shippingState {
  opsiPengambilan: OpsiPengambilan;
  pickUpByCourier: boolean;
  addressForCourier: Address | null;
  customExpeditions: { nama: string }[];
}

export const SET_OPSI_PENGAMBILAN = "@shipping/SET_OPSI_PENGAMBILAN";
export const SET_PICK_UP_BY_COURIER = "@shipping/SET_PICK_UP_BY_COURIER";
export const SET_ADDRESS_FOR_COURIER = "@shipping/SET_ADDRESS_FOR_COURIER";
export const ADD_CUSTOM_EXPEDITION = "@shipping/ADD_CUSTOM_EXPEDITION";

interface setOpsiPengambilan {
  type: typeof SET_OPSI_PENGAMBILAN;
  payload: { opsiPengambilan: OpsiPengambilan };
}

interface setPickUpByCourier {
  type: typeof SET_PICK_UP_BY_COURIER;
  payload: { pickUpByCourier: boolean };
}

interface setAddressForCourier {
  type: typeof SET_ADDRESS_FOR_COURIER;
  payload: { addressForCourier: shippingState["addressForCourier"] };
}

interface addCustomExpedition {
  type: typeof ADD_CUSTOM_EXPEDITION;
  payload: { expedition: string };
}

interface purgeAction {
  type: typeof PURGE;
}

export type shippingActionTypes =
  | setOpsiPengambilan
  | setPickUpByCourier
  | setAddressForCourier
  | addCustomExpedition
  | purgeAction;
