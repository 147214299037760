import { PURGE } from "redux-persist";

import {
  orderState,
  orderActionTypes,
  CHANGE_BODY_UNITS,
  SET_BODY_QTY,
  SET_AKSESORIS_QTY,
  CHANGE_AKSESORIS_QTY_STD,
  SET_AKSESORIS_LIST,
  CLEAR_ORDER,
} from "./types";

export default function OrderReducer(state = initialState, action: orderActionTypes): orderState {
  switch (action.type) {
    case CHANGE_BODY_UNITS:
      return { ...state, bodyUnits: action.payload.bodyUnits };

    case SET_BODY_QTY:
      return { ...state, bodyQty: action.payload.bodyQty };

    case SET_AKSESORIS_LIST:
      return { ...state, aksesoris: action.payload.aksesoris };

    case SET_AKSESORIS_QTY: {
      const { index: i, qty } = action.payload;

      return {
        ...state,
        aksesoris: { ...state.aksesoris, [i]: { ...state.aksesoris[i], qty } },
      };
    }

    case CHANGE_AKSESORIS_QTY_STD: {
      const { index: i, state: isStandardQty } = action.payload;
      return {
        ...state,
        aksesoris: {
          ...state.aksesoris,
          [i]: { ...state.aksesoris[i], isStandardQty },
        },
      };
    }

    case CLEAR_ORDER: {
      const payload = Object.keys(state.aksesoris).reduce((acc: AksesorisQtyList, _, i) => {
        acc[i] = { qty: "", isStandardQty: false };
        return acc;
      }, {});

      return {
        ...state,
        bodyQty: "",
        bodyUnits: "kg",
        aksesoris: payload,
      };
    }

    case PURGE:
      return initialState;

    default:
      return state;
  }
}

export const initialState: orderState = {
  bodyUnits: "kg",
  bodyQty: "",
  aksesoris: Object(),
};
