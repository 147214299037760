import { PURGE } from "redux-persist";

export interface mainState {
  selectedAddress: Record<string, Address | null>;
  selectedCabang: Cabang;
  selectedEkspedisi: string;
  selectedGroupKainId: number;
  selectedJenisKainName: string;
  selectedWarnaKain: WarnaKain | null;
  selectJenisPakaian: string;
  token?: string;
  idTransaksi?: string | null;
  noTransaksi: string;
  inProtectedRoute: boolean;
  noHp: string;
  nama: string;
}

export const SELECT_ADDRESS = "@main/SELECT_ADDRESS";
export const SELECT_CABANG = "@main/SELECT_CABANG";
export const SELECT_GROUP_KAIN = "@main/SELECT_GROUP_KAIN";
export const SELECT_JENIS_KAIN = "@main/SELECT_JENIS_KAIN";
export const SELECT_WARNA_KAIN = "@main/SELECT_WARNA_KAIN";
export const SELECT_JENIS_PAKAIAN = "@main/SELECT_JENIS_PAKAIAN";
export const SELECT_EKSPEDISI = "@main/SELECT_EKSPEDISI";
export const SET_TOKEN = "@main/SET_TOKEN";
export const SET_ID_TRANSAKSI = "@main/SET_ID_TRANSAKSI";
export const SET_NO_TRANSAKSI = "@main/SET_NO_TRANSAKSI";
export const SET_NO_TRANSAKSI_NO_HP = "@main/SET_NO_TRANSAKSI_NO_HP";
export const SET_IN_PROTECTED_ROUTE = "@main/SET_IN_PROTECTED_ROUTE";

interface setToken {
  type: typeof SET_TOKEN;
  payload: { token: string };
}

interface setIdTransaksi {
  type: typeof SET_ID_TRANSAKSI;
  payload: { idTransaksi: string | null };
}

interface setNoTransaksi {
  type: typeof SET_NO_TRANSAKSI;
  payload: { noTransaksi: string };
}

interface setNoTransaksiNoHp {
  type: typeof SET_NO_TRANSAKSI_NO_HP;
  payload: { noTransaksi: string; noHp: string; nama: string };
}

interface selectAddress {
  type: typeof SELECT_ADDRESS;
  payload: { address: Address | null };
}

interface selectEkspedisi {
  type: typeof SELECT_EKSPEDISI;
  payload: { ekspedisi: string };
}

interface selectCabang {
  type: typeof SELECT_CABANG;
  payload: { cabang: Cabang };
}

interface selectGroupKain {
  type: typeof SELECT_GROUP_KAIN;
  payload: { groupKainId: number };
}

interface selectJenisKain {
  type: typeof SELECT_JENIS_KAIN;
  payload: { jenisKainName: string };
}

interface selectWarnaKain {
  type: typeof SELECT_WARNA_KAIN;
  payload: { warnaKain: WarnaKain | null };
}

interface selectJenisPakaian {
  type: typeof SELECT_JENIS_PAKAIAN;
  payload: { jenisPakaian: string };
}

interface setInProtectedRoute {
  type: typeof SET_IN_PROTECTED_ROUTE;
  payload: { inProtectedRoute: boolean };
}

interface purgeAction {
  type: typeof PURGE;
}

export type mainActionTypes =
  | setToken
  | setNoTransaksi
  | setIdTransaksi
  | selectAddress
  | selectEkspedisi
  | selectCabang
  | selectWarnaKain
  | selectGroupKain
  | selectJenisKain
  | selectJenisPakaian
  | setInProtectedRoute
  | setNoTransaksiNoHp
  | purgeAction;
